import request from '@/api/request'

export function getOdometerReport (data) {
  const req = {
    deviceImei: data.deviceImei,
    startDate: data.startDate,
    endDate: data.endDate,
    pageNum: data.pageNum,
    pageSize: data.pageSize
  }
  return request({
    url: '/gps/report/getOdometerReport',
    method: 'post',
    data: req
  })
}

export function listBikeReport (data) {
  const req = {
    deviceImei: data.deviceImei,
    startDate: data.startDate,
    endDate: data.endDate,
    pageNum: data.pageNum,
    pageSize: data.pageSize
  }
  return request({
    url: '/gps/report/listBikeReport',
    method: 'post',
    data: req
  })
}

export function getDeviceAlarmReport (data) {
  const req = {
    deviceImei: data.deviceImei,
    startTime: data.startDate,
    endTime: data.endDate,
    pageNum: data.pageNum,
    pageSize: data.pageSize
  }
  return request({
    url: '/gps/report/getDeviceAlarmReport',
    method: 'post',
    data: req
  })
}

export function getOverSpeedAlarmReport (data) {
  const req = {
    deviceImei: data.deviceImei,
    startTime: data.startDate,
    endTime: data.endDate,
    pageNum: data.pageNum,
    pageSize: data.pageSize
  }
  return request({
    url: '/gps/report/getOverSpeedAlarmReport',
    method: 'post',
    data: req
  })
}
