export function getTodayZero () {
  let now = new Date(new Date(new Date().toLocaleDateString()).getTime())
  return now
}

export function getDateShortStr (value) {
  let val
  if (value instanceof Date) {
    val = value
  } else {
    val = new Date(value)
  }
  const year = val.getFullYear()
  const month = val.getMonth() + 1
  const day = val.getDate()
  return year + '-' + month + '-' + day
}

export function getDateFullStr (value) {
  let val
  if (value instanceof Date) {
    val = value
  } else {
    val = new Date(value)
  }
  const year = val.getFullYear()
  const month = val.getMonth() + 1
  const day = val.getDate()
  const hour = val.getHours()
  const minutes = val.getMinutes()
  const second = val.getSeconds()
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + second
}

export function addMonth (d, offset) {
  if (d instanceof Date && !isNaN(offset)) {
    let oldMonth = d.getMonth()
    let newMonth = oldMonth + offset
    d.setMonth(newMonth)
    return d
  }
  throw Error('argument type error')
}
